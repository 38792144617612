import DOMPurify from "dompurify";
import { HuitabPageManager } from "../../services/page-manager";
import { TagCommanderManager } from "../../services/tag-commander-manager";

const TARGET_BLANK = "_blank";

/**
 * Composant pour définir un comportement commun sur les balises hyperlink
 */
export default class HuitabAnchor extends HTMLAnchorElement {
  /**
   * Initialise une nouvelle instance de type {@type HuitabAnchor}.
   */
  constructor() {
    super();

    // Réagir au clic sur la balise
    this.addEventListener("click", this._onAnchorClick.bind(this, false));
    this.addEventListener("auxclick", this._onAnchorClick.bind(this, true));
  }

  /**
   * Réaction au clic sur la balise.
   * @param openLinkInNewTab {boolean} indique si l'URL doit être ouverte dans un nouvel onglet
   * @param event {Event} évènement de click
   * @return {boolean} Une valeur indiquant l'ouverture du lien hypertexte
   * @private
   */
  _onAnchorClick(openLinkInNewTab, event) {
    event.preventDefault();

    if (this.dataset.tagClick) {
      // Envoyer event tag commander de type click
      const tag = JSON.parse(this.dataset.tagClick);
      TagCommanderManager.tagClick(tag);
    }

    if (this.dataset.tagPage) {
      // Envoyer event tag commander de type page
      const tag = JSON.parse(this.dataset.tagPage);
      TagCommanderManager.tagPage(tag);
    }

    // Gestion de la redirection interne ou externe
    const url = (this.dataset.externalBaseUrl || "") + this.getAttribute("href");

    if (!url) {
      return false;
    }

    // Vérification si l'URL est sûre
    const isSafeUrl = DOMPurify.isValidAttribute("a", "href", url);

    // Si l'URL n'est pas sûre
    if (!isSafeUrl) {
      return false;
    }

    // Construction de l'URL sûre
    const safeUrl = DOMPurify.sanitize(`<a href="${url}"></a>`).replace(`<a href="`, "").replace(`"></a>`, "");

    // Ouverture dans un nouvel onglet si target="_blank" ou s'il y a une URL externe
    // Ou si l'utilisateur a cliqué avec la molette ou a pressé la touche ctrl
    if (
      this.getAttribute("target") === TARGET_BLANK ||
      this.dataset.externalBaseUrl ||
      openLinkInNewTab ||
      event.ctrlKey
    ) {
      HuitabPageManager.openLinkInNewTab(safeUrl);
    } else {
      // Sinon on redirige vers le lien dans l'onglet actuel
      HuitabPageManager.openLink(safeUrl);
    }

    return true;
  }
}
