import HuitabHeader from "../components/common/huitab-header";
import { PageManager } from "./page-manager";
import { huitabSettings } from "./settings-loader";
import { TagCommanderManager } from "./tag-commander-manager";

/**
 * Service pour la masterpage
 */
class HuitabMasterpageManager {
  /**
   * Initialise une nouvelle instance de type {@type HuitabMasterpageManager}.
   */
  constructor() {}

  /**
   * Construction masterpage
   */
  buildMasterpage() {
    window.document.title = PageManager.current.titre;

    if (PageManager.current.header) {
      this._customizeMasterHeader(PageManager.current.header);
    }

    if (PageManager.current.microapp) {
      this._customizeMasterMicroapp(PageManager.current.microapp);
    }

    if (PageManager.current.tag) {
      TagCommanderManager.tagPage(PageManager.current.tag);
    }

    if (window.location.hostname.endsWith(".local")) {
      document.querySelectorAll("a[data-external-base-url]").forEach(externalLink => {
        const externalBaseUrl = externalLink.getAttribute("data-external-base-url");
        if (externalBaseUrl.includes("route_externe")) {
          externalLink.setAttribute("data-external-base-url", huitabSettings.url.route_externe);
        } else if (externalBaseUrl.includes("compte_client_externe")) {
          externalLink.setAttribute("data-external-base-url", huitabSettings.url.compte_client_externe);
        } else if (externalBaseUrl.includes("facebook_url")) {
          externalLink.setAttribute("data-external-base-url", huitabSettings.url.facebook_url);
        } else if (externalBaseUrl.includes("twitter_url")) {
          externalLink.setAttribute("data-external-base-url", huitabSettings.url.twitter_url);
        } else if (externalBaseUrl.includes("linkedin_url")) {
          externalLink.setAttribute("data-external-base-url", huitabSettings.url.linkedin_url);
        } else if (externalBaseUrl.includes("instagram_url")) {
          externalLink.setAttribute("data-external-base-url", huitabSettings.url.instagram_url);
        } else if (externalBaseUrl.includes("accueil_open_data_url")) {
          externalLink.setAttribute("data-external-base-url", huitabSettings.url.accueil_open_data_url);
        } else if (externalBaseUrl.includes("bilan_mon_territoire_url")) {
          externalLink.setAttribute("data-external-base-url", huitabSettings.url.bilan_mon_territoire_url);
        }
      });

      document.querySelectorAll("a[href^='{{']").forEach(link => {
        if (link.href.includes("esp_dynamique_url")) {
          link.href = huitabSettings.url.esp_dynamique_url;
        }
      });
    }
  }

  /**
   * Construction masterpage header
   * @param {HeaderConfig} headerConfig La configuration du header
   * @private
   */
  _customizeMasterHeader(headerConfig) {
    HuitabHeader.setLienHeader({
      activeNavLinksDesktop: headerConfig.activeNavLinksDesktop,
      activeNavLinksMobile: headerConfig.activeNavLinksMobile,
    });

    // Remplir le fil d'Ariane desktop
    HuitabHeader.setFilArianeDesktop(headerConfig.filArianeDesktop);

    // Remplir le titre de la page mobile
    HuitabHeader.setFilArianeMobile(headerConfig.filArianeMobile);
  }

  /**
   *
   * @param {MicroappConfig} microappConfig
   * @private
   */
  _customizeMasterMicroapp(microappConfig) {
    const blocMicroapp = document.querySelector("#huitab-master-microapp");
    blocMicroapp.parentElement.classList.add(microappConfig.id);
    blocMicroapp.insertAdjacentHTML(
      "beforebegin",
      `<div id="${microappConfig.id}" is="${microappConfig.is}" style="width: 100%"></div>`
    );
  }
}

const MasterpageManager = new HuitabMasterpageManager();
export { MasterpageManager };
